import React from 'react';
import { Switch } from 'react-router';
import { Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import AccountView from '../app/accounts/views/AccountView';
import AccountDetailView from '../app/accounts/views/AccountDetailView';
import NewContractView from '../app/contracts/views/NewContractView';
import ShippingView from '../app/shipping/views/ShippingView';
import ActListView from '../app/accounting/acts/views/ActListView';
import TransactionListView from '../app/accounting/transaction/views/TransactionListView';
import AccountInvitesListView from '../app/accounts/views/AccountInvitesListView';
import AccountDialogListView from '../app/chat/views/AccountDialogListView';
import AccountDialogDetailView from '../app/chat/views/AccountDialogDetailView';
import ShippingDetailView from '../app/shipping/views/ShippingDetailView';
import ClientMarketingListView from '../app/marketing/views/ClientMarketingListView';
import ClientActiveStatView from '../app/statistics/views/ClientActiveStatView';
import ReconcillationListView from '../app/accounting/reconcillation/views/ReconcillationListView';
import DetailingListView from '../app/accounting/detailing/views/DetailingListView';
import AccountNotificationPreferencesView from '../app/accounts/views/AccountNotificationPreferencesView';

function AccountRouter({ match }) {
  return (
    <Switch>
      <PrivateRoute exact path={match.url} component={AccountView} />
      <PrivateRoute exact path={`${match.url}/chart`} component={ClientActiveStatView} />
      <PrivateRoute exact path={`${match.url}/:id`} component={AccountDetailView} />
      <PrivateRoute exact path={`${match.url}/:accountId/new_contract`} component={NewContractView} />
      <PrivateRoute exact path={`${match.url}/:accountId/shipping`} component={ShippingView} />
      <PrivateRoute exact path={`${match.url}/:accountId/acts`} component={ActListView} />
      <PrivateRoute exact path={`${match.url}/:accountId/transactions`} component={TransactionListView} />
      <PrivateRoute exact path={`${match.url}/:accountId/shipping/:waybillId`} component={ShippingDetailView} />
      <PrivateRoute exact path={`${match.url}/:accountId/contacts`} component={AccountInvitesListView} />
      <PrivateRoute exact path={`${match.url}/:accountId/dialogs`} component={AccountDialogListView} />
      <PrivateRoute exact path={`${match.url}/:accountId/dialogs/:dialogId`} component={AccountDialogDetailView} />
      <PrivateRoute exact path={`${match.url}/:accountId/marketing`} component={ClientMarketingListView} />
      <PrivateRoute exact path={`${match.url}/:accountId/reconcillation`} component={ReconcillationListView} />
      <PrivateRoute exact path={`${match.url}/:accountId/detailing`} component={DetailingListView} />
      <PrivateRoute exact path={`${match.url}/:accountId/notification_preferences`} component={AccountNotificationPreferencesView} />
      <Redirect to="/404" />
    </Switch>
  );
}

export default AccountRouter;
