import snakeCase from 'lodash/snakeCase';
import { RcFile } from 'antd/lib/upload/interface';
import {
  AccountTenderRate,
  ADD_EXTRA_ACCOUNT_MARKETING,
  ADD_MARKETING,
  ADD_TENDER_RATE,
  AddExtraAccountMarketing,
  AddMarketing,
  AddTenderRate,
  ClientMarketingImportStatus,
  ClientMarketingTableParams,
  ClientTenderTableParams,
  ExtraAccountMarketing, ExtraAccountMarketingTableParams,
  MarketingClient,
  MarketingClientLog,
  OrderListItem,
  OrderStatus,
  REMOVE_EXTRA_ACCOUNT_MARKETING,
  REMOVE_MARKETING,
  REMOVE_TENDER_RATE,
  RemoveExtraAccountMarketing,
  RemoveMarketing,
  RemoveTenderRate,
  REPLACE_EXTRA_ACCOUNT_MARKETING,
  REPLACE_MARKETING,
  REPLACE_SERVICE_LIMIT,
  REPLACE_TENDER_RATE,
  ReplaceExtraAccountMarketing,
  ReplaceMarketing,
  ReplaceTenderRate,
  RESET_EXTRA_ACCOUNT_MARKETING_LIST,
  RESET_MARKETING_CLIENT_LOG,
  RESET_MARKETING_IMPORT,
  RESET_MARKETING_LIST,
  RESET_SERVICE_LIMIT_LIST,
  RESET_TENDER_RATE_LIST,
  ResetExtraAccountMarketingList,
  ResetMarketingClientLog,
  ResetMarketingImport,
  ResetMarketingList,
  ResetTenderRateList,
  ServiceLimit,
  SET_EXTRA_ACCOUNT_MARKETING_COUNT,
  SET_EXTRA_ACCOUNT_MARKETING_LIST,
  SET_MARKETING_CLIENT_LOG,
  SET_MARKETING_CLIENT_LOG_COUNT,
  SET_MARKETING_COUNT,
  SET_MARKETING_IMPORT_ID,
  SET_MARKETING_IMPORT_STATUS,
  SET_MARKETING_LIST,
  SET_SERVICE_LIMIT_LIST,
  SET_STORE_ITEMS,
  SET_STORE_ORDER_COUNT,
  SET_STORE_ORDER_DETAIL,
  SET_STORE_ORDER_LIST,
  SET_TENDER_RATE_COUNT,
  SET_TENDER_RATE_LIST,
  SetExtraAccountMarketingCount,
  SetExtraAccountMarketingList,
  SetMarketingClientLog,
  SetMarketingClientLogCount,
  SetMarketingCount,
  SetMarketingImportId,
  SetMarketingImportStatus,
  SetMarketingList,
  SetStoreItems,
  SetStoreOrderCount,
  SetStoreOrderDetail,
  SetStoreOrderList,
  SetTenderRateCount,
  SetTenderRateList,
  StoreItem,
  StoreOrderDetail,
} from './types';
import { ThunkAction } from '../../utils/types';
import { adaptToApi } from '../../utils/adapter';

export const setMarketingList = (marketingList: Array<MarketingClient>): SetMarketingList => ({
  type: SET_MARKETING_LIST,
  marketingList,
});

export const setMarketingCount = (count: number): SetMarketingCount => ({
  type: SET_MARKETING_COUNT,
  count,
});

export const addMarketing = (marketing: MarketingClient): AddMarketing => ({
  type: ADD_MARKETING,
  marketing,
});

export const removeMarketing = (marketingId: number): RemoveMarketing => ({
  type: REMOVE_MARKETING,
  marketingId,
});

export const resetMarketingList = (): ResetMarketingList => ({
  type: RESET_MARKETING_LIST,
});

export const replaceMarketing = (marketing: MarketingClient): ReplaceMarketing => ({
  type: REPLACE_MARKETING,
  marketing,
});

export const setServiceLimitList = (serviceLimitList: Array<ServiceLimit>) => ({
  type: SET_SERVICE_LIMIT_LIST,
  serviceLimitList,
});

export const resetServiceLimitList = () => ({
  type: RESET_SERVICE_LIMIT_LIST,
});

export const replaceServiceLimit = (serviceLimit: ServiceLimit) => ({
  type: REPLACE_SERVICE_LIMIT,
  serviceLimit,
});

export const setMarketingClientLog = (logs: MarketingClientLog[]): SetMarketingClientLog => ({
  type: SET_MARKETING_CLIENT_LOG,
  logs,
});

export const setMarketingClientLogCount = (count: number): SetMarketingClientLogCount => ({
  type: SET_MARKETING_CLIENT_LOG_COUNT,
  count,
});

export const resetMarketingClientLog = (): ResetMarketingClientLog => ({
  type: RESET_MARKETING_CLIENT_LOG,
});

export const setTenderRateList = (tenderRateList: AccountTenderRate[]): SetTenderRateList => ({
  type: SET_TENDER_RATE_LIST,
  tenderRateList,
});

export const resetTenderRateList = (): ResetTenderRateList => ({
  type: RESET_TENDER_RATE_LIST,
});

export const replaceTenderRate = (tenderRate: AccountTenderRate): ReplaceTenderRate => ({
  type: REPLACE_TENDER_RATE,
  tenderRate,
});

export const removeTenderRate = (tenderRateId: number): RemoveTenderRate => ({
  type: REMOVE_TENDER_RATE,
  tenderRateId,
});

export const addTenderRate = (tenderRate: AccountTenderRate): AddTenderRate => ({
  type: ADD_TENDER_RATE,
  tenderRate,
});

export const setTenderRateCount = (count: number): SetTenderRateCount => ({
  type: SET_TENDER_RATE_COUNT,
  count,
});

export const setMarketingImportId = (importId: string): SetMarketingImportId => ({
  type: SET_MARKETING_IMPORT_ID,
  importId,
});

export const setMarketingImportStatus = (status: ClientMarketingImportStatus): SetMarketingImportStatus => ({
  type: SET_MARKETING_IMPORT_STATUS,
  status,
});

export const resetMarketingImport = (): ResetMarketingImport => ({
  type: RESET_MARKETING_IMPORT,
});

export const setStoreItems = (items: StoreItem[]): SetStoreItems => ({
  type: SET_STORE_ITEMS,
  items,
});

export const setStoreOrderList = (items: OrderListItem[]): SetStoreOrderList => ({
  type: SET_STORE_ORDER_LIST,
  items,
});

export const setStoreOrderCount = (count: number): SetStoreOrderCount => ({
  type: SET_STORE_ORDER_COUNT,
  count,
});

export const setStoreOrderDetail = (detail: StoreOrderDetail): SetStoreOrderDetail => ({
  type: SET_STORE_ORDER_DETAIL,
  detail,
});

export const setExtraAccountMarketingList = (list: ExtraAccountMarketing[]): SetExtraAccountMarketingList => ({
  type: SET_EXTRA_ACCOUNT_MARKETING_LIST,
  list,
});

export const resetExtraAccountMarketingList = (): ResetExtraAccountMarketingList => ({
  type: RESET_EXTRA_ACCOUNT_MARKETING_LIST,
});

export const replaceExtraAccountMarketing = (marketing: ExtraAccountMarketing): ReplaceExtraAccountMarketing => ({
  type: REPLACE_EXTRA_ACCOUNT_MARKETING,
  marketing,
});

export const removeExtraAccountMarketing = (marketingId: number): RemoveExtraAccountMarketing => ({
  type: REMOVE_EXTRA_ACCOUNT_MARKETING,
  marketingId,
});

export const addExtraAccountMarketing = (marketing: ExtraAccountMarketing): AddExtraAccountMarketing => ({
  type: ADD_EXTRA_ACCOUNT_MARKETING,
  marketing,
});

export const setExtraAccountMarketingCount = (count: number): SetExtraAccountMarketingCount => ({
  type: SET_EXTRA_ACCOUNT_MARKETING_COUNT,
  count,
});

export const loadMarketingList = (accountId: string | number, params: ClientMarketingTableParams): ThunkAction => (dispatch, getState, http) => {
  const {
    page, search, enabled, weight, weightCondition, ...rest
  } = params;

  const data: anyObject = {
    page,
    account_id: accountId,
  };

  if (search) data.search = search;
  if (enabled) data.enabled = enabled;

  const filters: anyObject[] = [];

  if (weight && weightCondition) {
    filters.push({
      field: 'weight',
      value: weight,
      condition: weightCondition,
    });
  }

  Object.keys(rest).forEach((key) => {
    // @ts-ignore
    const val = rest[key];
    if (val) {
      filters.push({
        field: snakeCase(key),
        text_value: val,
      });
    }
  });

  if (filters.length) data.filters = filters;

  return http.post(
    '/api/v1/rates/marketing/',
    data,
  ).then(
    (response: { marketing: Array<MarketingClient>, count: number }) => {
      dispatch(setMarketingList(response.marketing));
      dispatch(setMarketingCount(response.count));
      return Promise.resolve(response);
    },
  );
};

export const createClientMarketing = (data: any): ThunkAction => (dispatch, getState, http) => http.post(
  '/api/v1/rates/marketing/create/', data,
).then(
  (response: MarketingClient) => {
    dispatch(addMarketing(response));
    return Promise.resolve();
  },
);
export const updateClientMarketing = (data: any, marketingId: string | number): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/rates/marketing/${marketingId}/update/`, data,
).then(
  (response: MarketingClient) => {
    dispatch(replaceMarketing(response));
    return Promise.resolve();
  },
);

export const deleteClientMarketing = (marketingId: string | number): ThunkAction => (dispatch, getState, http) => http.delete(
  `/api/v1/rates/marketing/${marketingId}/delete/`,
).then(
  () => {
    if (typeof marketingId === 'string') dispatch(removeMarketing(parseInt(marketingId)));
    else dispatch(removeMarketing(marketingId));
  },
);

export const switchClientMarketing = (marketingId: string | number, enabled: boolean): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/rates/marketing/${marketingId}/switch/`, { enabled },
).then(
  (response: MarketingClient) => {
    dispatch(replaceMarketing(response));
    return Promise.resolve();
  },
);

export const loadServiceLimitList = (accountId: string | number): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/rates/service/limit/list/',
  { account_id: accountId }).then(
  (response: { items: Array<ServiceLimit> }) => {
    dispatch(setServiceLimitList(response.items));
    return Promise.resolve(response);
  },
);

export const createServiceLimit = (data: any): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/rates/service/limit/create/', data);
export const updateServiceLimit = (data: any, limitId: string | number): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/rates/service/limit/${limitId}/update/`, data,
);

export const createAccountInsurance = (data: any): ThunkAction => (dispatch, getState, http) => http.post(
  '/api/v1/cargoes/insurance/create/', data,
);
export const updateAccountInsurance = (data: any, accountInsuranceId: string | number): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/cargoes/insurance/${accountInsuranceId}/update/`, data,
);
export const deleteAccountInsurance = (accountInsuranceId: string | number): ThunkAction => (dispatch, getState, http) => http.delete(
  `/api/v1/cargoes/insurance/${accountInsuranceId}/delete/`,
);

export const loadMarketingLog = (marketingId: string | number, page = 1): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/rates/marketing/${marketingId}/log/`,
  { page },
).then(
  ({ count, logs }: { count: number, logs: MarketingClientLog[] }) => {
    dispatch(setMarketingClientLog(logs));
    dispatch(setMarketingClientLogCount(count));
  },
);

export const loadAccountTenderRateList = (accountId: number, params: ClientTenderTableParams): ThunkAction => (dispatch, getState, http) => {
  const {
    page, search, enabled, weight, weightCondition, ...rest
  } = params;

  const data: anyObject = {
    page,
  };

  if (search) data.search = search;
  if (enabled) data.enabled = enabled;

  const filters: anyObject[] = [];

  if (weight && weightCondition) {
    filters.push({
      field: 'weight',
      value: weight,
      condition: weightCondition,
    });
  }

  Object.keys(rest).forEach((key) => {
    // @ts-ignore
    const val = rest[key];
    if (val) {
      filters.push({
        field: snakeCase(key),
        text_value: val,
      });
    }
  });

  if (filters.length) data.filters = filters;

  return http.post(
    `/api/v1/rates/tender/${accountId}/rate/list/`,
    data,
  ).then(
    ({ rates, count }: { rates: AccountTenderRate[], count: number, }) => {
      dispatch(setTenderRateList(rates));
      dispatch(setTenderRateCount(count));
      return Promise.resolve(rates);
    },
  );
};

export const switchAccountTenderRate = (tenderRateId: number, enabled: boolean): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/rates/tender/${tenderRateId}/rate/switch/`,
  { enabled },
).then(
  (tenderRate: AccountTenderRate) => {
    dispatch(replaceTenderRate(tenderRate));
    return Promise.resolve(tenderRate);
  },
);

export const deleteTenderRate = (tenderRateId: number): ThunkAction => (dispatch, getState, http) => http.delete(
  `/api/v1/rates/tender/${tenderRateId}/rate/delete/`,
).then(
  () => {
    dispatch(removeTenderRate(tenderRateId));
    return Promise.resolve();
  },
);

export const createTenderRate = (data: anyObject): ThunkAction => (dispatch, getState, http) => http.post(
  '/api/v1/rates/tender/rate/create/',
  adaptToApi(data),
).then(
  (tenderRate: AccountTenderRate) => {
    dispatch(addTenderRate(tenderRate));
    return Promise.resolve(tenderRate);
  },
);

export const updateTenderRate = (tenderRateId: number, data: anyObject): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/rates/tender/${tenderRateId}/rate/update/`,
  adaptToApi(data),
).then(
  (tenderRate: AccountTenderRate) => {
    dispatch(replaceTenderRate(tenderRate));
    return Promise.resolve(tenderRate);
  },
);

export const deleteMultipleTenderRates = (accountId: string | number, selectedTariffs: number[]): ThunkAction<Promise<number>> => (
  dispatch, getState, http,
) => http.delete(
  `/api/v1/rates/tender/${accountId}/rate/delete_multiple/`,
  {
    tender_rate_id_list: selectedTariffs,
  },
).then(
  ({ newCount }: { newCount: number }) => Promise.resolve(newCount),
);

export const acceptMarketingImportWs = (payload: ClientMarketingImportStatus): ThunkAction<void> => (dispatch, getState) => {
  const { marketingImportId } = getState().marketing;

  if (marketingImportId === payload.importId) {
    dispatch(setMarketingImportStatus(payload));
  }
};

acceptMarketingImportWs.wsType = 'client_marketing';

export const importClientMarketing = (accountId: string | number, file: RcFile | null): ThunkAction => (dispatch, getState, http) => {
  const data = new FormData();
  if (file) data.append('file', file);
  return http.put(`/api/v1/rates/marketing/import/${accountId}/`, data, { json: false }).then(
    ({ importId }: { importId: string }) => {
      dispatch(setMarketingImportId(importId));
      return Promise.resolve();
    },
  );
};

export const loadStoreItems = (): ThunkAction<void> => (dispatch, getState, http) => http.get(
  '/api/v1/marketing/store/items/',
).then(
  ({ items }: { items: StoreItem[] }) => dispatch(setStoreItems(items)),
);

export const loadOrderList = ({ page, ...filters }: anyObject): ThunkAction => (dispatch, getState, http) => {
  const params: anyObject = { page };
  if (filters.status && filters.status.length > 0) params.status = filters.status;

  return http.get('/api/v1/marketing/order/list/', params).then(
    ({ items, count }: { items: OrderListItem[], count: number }) => {
      dispatch(setStoreOrderList(items));
      dispatch(setStoreOrderCount(count));
      return Promise.resolve();
    },
  );
};

export const loadOrderDetail = (orderId: string | number): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/marketing/order/${orderId}/detail/`,
).then(
  (response: StoreOrderDetail) => {
    dispatch(setStoreOrderDetail(response));
    return Promise.resolve(response);
  },
);

export const approveStoreOrder = (orderId: string | number): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/marketing/order/${orderId}/approve/`,
).then(
  () => {
    const detail = getState().marketing.storeOrderDetail;
    if (detail && detail.orderId == orderId) dispatch(setStoreOrderDetail({ ...detail, status: OrderStatus.APPROVED }));
    return Promise.resolve();
  },
);

export const declineStoreOrder = (orderId: string | number): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/marketing/order/${orderId}/decline/`,
).then(
  () => {
    const detail = getState().marketing.storeOrderDetail;
    if (detail && detail.orderId == orderId) {
      dispatch(setStoreOrderDetail({
        ...detail,
        status: OrderStatus.CANCELLED,
      }));
    }
    return Promise.resolve();
  },
);

export const getExtraAccountMarketingList = (accountId: number | string, params: ExtraAccountMarketingTableParams): ThunkAction => (dispatch, getState, http) => {
  const {
    page, search, enabled, weight, weightCondition, ...rest
  } = params;
  const data: anyObject = {
    page,
  };

  if (search) data.search = search;
  if (enabled) data.enabled = enabled;

  const filters: anyObject[] = [];

  if (weight && weightCondition) {
    filters.push({
      field: 'weight',
      value: weight,
      condition: weightCondition,
    });
  }

  Object.keys(rest).forEach((key) => {
    // @ts-ignore
    const val = rest[key];
    if (val) {
      filters.push({
        field: snakeCase(key),
        text_value: val,
      });
    }
  });

  if (filters.length) data.filters = filters;

  return http.post(`/api/v1/rates/extra_marketing/${accountId}/list/`, data).then(
    ({ items, count }: { items: ExtraAccountMarketing[], count: number }) => {
      dispatch(setExtraAccountMarketingList(items));
      dispatch(setExtraAccountMarketingCount(count));
      return Promise.resolve(items);
    },
  );
};

export const createExtraAccountMarketing = (data: anyObject): ThunkAction => (dispatch, getState, http) => http.post(
  '/api/v1/rates/extra_marketing/create/',
  data,
).then(
  (res: ExtraAccountMarketing) => {
    dispatch(addExtraAccountMarketing(res));
    return Promise.resolve(res);
  },
);

export const extraAccountMarketingUpdate = (extraAccountMarketingId: number, data: anyObject): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/rates/extra_marketing/${extraAccountMarketingId}/update/`,
  data,
).then(
  (resp: ExtraAccountMarketing) => {
    dispatch(replaceExtraAccountMarketing(resp));
    return Promise.resolve(resp);
  },
);

export const extraAccountMarketingSwitch = (extraAccountMarketingId: number, enabled: boolean): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/rates/extra_marketing/${extraAccountMarketingId}/switch/`,
  { enabled },
).then(
  (resp: ExtraAccountMarketing) => {
    dispatch(replaceExtraAccountMarketing(resp));
    return Promise.resolve(resp);
  },
);

export const extraAccountMarketingDelete = (extraAccountMarketingId: number): ThunkAction => (dispatch, getState, http) => http.delete(
  `/api/v1/rates/extra_marketing/${extraAccountMarketingId}/delete/`,
).then(
  () => {
    dispatch(removeExtraAccountMarketing(extraAccountMarketingId));
    return Promise.resolve();
  },
);
