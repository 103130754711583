import {
  AccountTenderRate,
  ADD_EXTRA_ACCOUNT_MARKETING,
  ADD_MARKETING,
  ADD_TENDER_RATE,
  ClientMarketingImportStatus,
  ExtraAccountMarketing,
  MarketingAction,
  MarketingClient,
  MarketingClientLog,
  OrderListItem,
  REMOVE_EXTRA_ACCOUNT_MARKETING,
  REMOVE_MARKETING,
  REMOVE_TENDER_RATE,
  REPLACE_EXTRA_ACCOUNT_MARKETING,
  REPLACE_MARKETING,
  REPLACE_TENDER_RATE,
  RESET_EXTRA_ACCOUNT_MARKETING_LIST,
  RESET_MARKETING_CLIENT_LOG,
  RESET_MARKETING_IMPORT,
  RESET_MARKETING_LIST,
  RESET_TENDER_RATE_LIST,
  ServiceLimit,
  SET_EXTRA_ACCOUNT_MARKETING_COUNT,
  SET_EXTRA_ACCOUNT_MARKETING_LIST,
  SET_MARKETING_CLIENT_LOG,
  SET_MARKETING_CLIENT_LOG_COUNT,
  SET_MARKETING_COUNT,
  SET_MARKETING_IMPORT_ID,
  SET_MARKETING_IMPORT_STATUS,
  SET_MARKETING_LIST,
  SET_SERVICE_LIMIT_LIST,
  SET_STORE_ITEMS,
  SET_STORE_ORDER_COUNT,
  SET_STORE_ORDER_DETAIL,
  SET_STORE_ORDER_LIST,
  SET_TENDER_RATE_COUNT,
  SET_TENDER_RATE_LIST,
  StoreItem,
  StoreOrderDetail,
} from './types';
import { replaceByFieldValue } from '../../utils/array/replace';
import { removeByFieldValue } from '../../utils/array/remove';

export type MarketingState = {
  marketingList: Array<MarketingClient>,
  marketingCount: number,
  serviceLimitList: Array<ServiceLimit>,
  marketingClientLog: MarketingClientLog[],
  marketingClientLogCount: number,
  marketingImportId: string | null,
  marketingImportStatus: ClientMarketingImportStatus | null,

  tenderRateList: AccountTenderRate[],
  tenderRateCount: number,

  storeItems: StoreItem[],
  storeOrderItems: OrderListItem[],
  storeOrderCount: number,
  storeOrderDetail: null | StoreOrderDetail,

  extraMarketing: {
    count: number,
    list: ExtraAccountMarketing[],
  }
};

const initialState: MarketingState = {
  marketingList: [],
  marketingCount: 0,
  serviceLimitList: [],
  marketingClientLog: [],
  marketingClientLogCount: 0,
  marketingImportId: null,
  marketingImportStatus: null,

  tenderRateList: [],
  tenderRateCount: 0,

  storeItems: [],
  storeOrderItems: [],
  storeOrderCount: 0,
  storeOrderDetail: null,

  extraMarketing: {
    count: 0,
    list: [],
  },
};

export default function (state = initialState, action: MarketingAction) {
  switch (action.type) {
    case SET_MARKETING_LIST:
      return { ...state, marketingList: action.marketingList };
    case SET_MARKETING_COUNT:
      return { ...state, marketingCount: action.count };
    case RESET_MARKETING_LIST:
      return { ...state, marketingList: initialState.marketingList };
    case REPLACE_MARKETING:
      return { ...state, marketingList: replaceByFieldValue(state.marketingList, 'marketingId', action.marketing) };
    case SET_SERVICE_LIMIT_LIST:
      return { ...state, serviceLimitList: action.serviceLimitList };
    case SET_MARKETING_CLIENT_LOG_COUNT:
      return { ...state, marketingClientLogCount: action.count };
    case SET_MARKETING_CLIENT_LOG:
      return { ...state, marketingClientLog: action.logs };
    case RESET_MARKETING_CLIENT_LOG:
      return { ...state, marketingClientLog: initialState.marketingClientLog };
    case SET_TENDER_RATE_LIST:
      return { ...state, tenderRateList: action.tenderRateList };
    case RESET_TENDER_RATE_LIST:
      return { ...state, tenderRateList: initialState.tenderRateList, tenderRateCount: initialState.tenderRateCount };
    case REPLACE_TENDER_RATE:
      return { ...state, tenderRateList: replaceByFieldValue(state.tenderRateList, 'tenderRateId', action.tenderRate) };
    case REMOVE_TENDER_RATE:
      return {
        ...state,
        tenderRateList: removeByFieldValue(state.tenderRateList, 'tenderRateId', action.tenderRateId),
        tenderRateCount: state.tenderRateCount - 1,
      };
    case ADD_TENDER_RATE:
      return {
        ...state,
        tenderRateList: [...state.tenderRateList, action.tenderRate],
        tenderRateCount: state.tenderRateCount + 1,
      };
    case SET_TENDER_RATE_COUNT:
      return { ...state, tenderRateCount: action.count };
    case ADD_MARKETING:
      return {
        ...state,
        marketingList: [...state.marketingList, action.marketing],
        marketingCount: state.marketingCount + 1,
      };
    case REMOVE_MARKETING:
      return {
        ...state,
        marketingList: removeByFieldValue(state.marketingList, 'marketingId', action.marketingId),
        marketingCount: state.marketingCount - 1,
      };
    case SET_MARKETING_IMPORT_ID:
      return { ...state, marketingImportId: action.importId };
    case SET_MARKETING_IMPORT_STATUS:
      return { ...state, marketingImportStatus: action.status };
    case RESET_MARKETING_IMPORT:
      return {
        ...state,
        marketingImportId: initialState.marketingImportId,
        marketingImportStatus: initialState.marketingImportStatus
      };
    case SET_STORE_ITEMS:
      return { ...state, storeItems: action.items };
    case SET_STORE_ORDER_LIST:
      return { ...state, storeOrderItems: action.items };
    case SET_STORE_ORDER_COUNT:
      return { ...state, storeOrderCount: action.count };
    case SET_STORE_ORDER_DETAIL:
      return { ...state, storeOrderDetail: action.detail };
    case SET_EXTRA_ACCOUNT_MARKETING_LIST:
      return { ...state, extraMarketing: { ...state.extraMarketing, list: action.list } };
    case RESET_EXTRA_ACCOUNT_MARKETING_LIST:
      return { ...state, extraMarketing: initialState.extraMarketing };
    case REPLACE_EXTRA_ACCOUNT_MARKETING:
      return {
        ...state,
        extraMarketing: {
          ...state.extraMarketing,
          list: replaceByFieldValue(state.extraMarketing.list, 'extraAccountMarketingId', action.marketing)
        },
      };
    case REMOVE_EXTRA_ACCOUNT_MARKETING:
      return {
        ...state,
        extraMarketing: {
          ...state.extraMarketing,
          list: removeByFieldValue(state.extraMarketing.list, 'extraAccountMarketingId', action.marketingId),
          count: state.extraMarketing.count - 1,
        },
      };
    case ADD_EXTRA_ACCOUNT_MARKETING:
      return {
        ...state,
        extraMarketing: {
          ...state.extraMarketing,
          list: [...state.extraMarketing.list, action.marketing],
          count: state.extraMarketing.count + 1,
        },
      };
    case SET_EXTRA_ACCOUNT_MARKETING_COUNT:
      return { ...state, extraMarketing: { ...state.extraMarketing, count: action.count }, };
    default:
      return state;
  }
}
